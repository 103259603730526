import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from 'landing/Landing.vue'
import TermsPrivacy from 'landing/TermsPrivacy.vue'
import PermissionsPage from 'landing/PermissionsPage.vue'
import Pricing from 'landing/Pricing.vue'
import Spaceli2 from "../landing/Spaceli-2.vue";

Vue.use(VueRouter)

export enum RouteName {
	LANDING = 'landing',
	TERMS_PRIVACY = 'terms-privacy',
	PERMISSIONS = 'permissions',
	PRICING = 'pricing',
	SPACELI_2 = 'spaceli_2',
}

const routes = [
	{
		path: '/',
		name: RouteName.LANDING,
		component: Landing,
	},
	{
		path: '/spaceli-2',
		name: RouteName.SPACELI_2,
		component: Spaceli2,
	},
	{
		path: '/terms-privacy',
		name: RouteName.TERMS_PRIVACY,
		component: TermsPrivacy,
	},
	{
		path: '/permissions',
		name: RouteName.PERMISSIONS,
		component: PermissionsPage,
	},
	{
		path: '/pricing',
		name: RouteName.PRICING,
		component: Pricing,
	},
	{
		path: '*',
		redirect: '/'
	},
]

export const router = new VueRouter({
	mode: 'history',
	routes
})
