<template>
	<div>Pricing</div>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'

	@Component({
		components: {
			//
		}
	})

	export default class Pricing extends Vue {
		//
	}
</script>

<style scoped>

</style>
