import 'ClassComponentHooks'
import 'assets/index.css'

import Vue from 'vue'
import App from './App.vue'
import {router} from 'router/Router'

Vue.config.productionTip = false

new Vue({
	el: '#app',
	router,
	render: h => h(App)
})
