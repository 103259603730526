<template>
	<div class="lp-main">
		<div class="lp-container">
			<LandingHeader/>
			<div class="lp-content">
				<div class="header-1 margin-b-32">Permissions</div>
				<div class="paragraph-text margin-b-16">When you sign up to Spaceli, you are asked to accept the following authorizations:</div>

				<table class="margin-b-64">
					<thead>
					<tr>
						<th>
							<h3>Authorization scopes</h3>
						</th>
						<th>
							<h3>Explanations</h3>
						</th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td>
							<div class="scope-item">
								<img class="scope-img" src="./img/drive_48.png">
								<div class="scope-text">See, edit, create, and delete only the specific Google Drive files you use with this app</div>
							</div>
						</td>
						<td>
							This scope is required to perform the following actions:
							<ul>
								<li>Creating a folder for space</li>
								<li>Creating files/folders in your space</li>
								<li>Previewing file content</li>
								<li>Accessing the users shared folders when requested by the user via browser URL.</li>
								<li>Moving files/folders you explicitly opened, shared, or created with Spaceli.</li>
								<li>Copying files/folders you explicitly opened, shared, or created with Spaceli.</li>
							</ul>
						</td>
					</tr>
<!--					<tr>-->
<!--						<td>-->
<!--							<div class="scope-item">-->
<!--								<img class="scope-img" src="./img/contacts.png">-->
<!--								<div class="scope-text">See and download your contacts</div>-->
<!--							</div>-->
<!--						</td>-->
<!--						<td>-->
<!--							<ul>-->
<!--								<li>Read your contacts to allow sending invites</li>-->
<!--							</ul>-->
<!--						</td>-->
<!--					</tr>-->
<!--					<tr>-->
<!--						<td>-->
<!--							<div class="scope-item">-->
<!--								<img class="scope-img" src="./img/act.png">-->
<!--								<div class="scope-text">View the activity record of files in your Google Drive</div>-->
<!--							</div>-->
<!--						</td>-->
<!--						<td>-->
<!--							This scope enables you to view activity for space files<br>-->
<!--						</td>-->
<!--					</tr>-->
					</tbody>
				</table>

				<h3 class="margin-b-16">Does the Spaceli server access or index my files?</h3>
				<div class="paragraph-text margin-b-32">
					No. At no point does the Spaceli server view or access your files. All requests to the Google Drive API are done through the Google Client API. Which means your data is securely accessed by your browser, the server never see's it or has access to it.
				</div>

				<h3 class="margin-b-16">Why does Spaceli need full access to Drive?</h3>
				<div class="paragraph-text margin-b-32">
					In order to share projects with your team members Spaceli needs access to the files in your shared folder. Full access is required to access the shared folder. It also is required for files that are not explicitly created by the app.
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import LandingHeader from 'landing/LandingHeader.vue'

	@Component({
		components: {
			LandingHeader
		}
	})

	export default class PermissionsPage extends Vue {
		//
	}
</script>

<style scoped>
	table {
		width: 100%;
		border-collapse: collapse;
		text-align: left;
	}

	th {
		padding: 12px 0;
	}

	thead tr {
		border-bottom: 2px solid var(--g-20);
	}

	td {
		padding: 16px 24px 16px 0;
	}

	tr:not(:last-child) {
		border-bottom: 1px solid var(--g-20);
	}

	.scope-item {
		display: flex;
		align-items: center;
	}

	.scope-img {
		width: 24px;
		height: 24px;
		margin-right: 12px;
	}
</style>
