<template>
	<div class="landing-header">
		<router-link to="/" class="logo"><LogoTextGradient/></router-link>
		<div class="header-middle">
			<slot></slot>
		</div>
		<div class="mr-4 items-center hidden lg:flex">
			<div class="mr-3">Spaceli 1.0 will be discontinued on December 1st</div>
			<router-link to="/spaceli-2">
				<IconQuestion class="w-5 h-5"/>
			</router-link>
		</div>
		<div class="header-right mr-2">
			<a href="https://app.spaceli.io/login" class="rounded-button-medium db-50-outline-button"><span class="hidden sm:inline mr-1">Sign in to </span>Spaceli 1.0</a>
		</div>

		<div class="header-right">
			<a href="https://app2.spaceli.io/login" class="rounded-button-medium db-50-button"><span class="hidden sm:inline mr-1">Sign in to </span>Spaceli 2.0</a>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import LogoTextGradient from 'icons/logo/LogoTextGradient.vue'
	import IconQuestion from "../icons/icons/IconQuestion.vue";

	@Component({
		components: {
			IconQuestion,
			LogoTextGradient
		}
	})

	export default class LandingHeader extends Vue {
		//
	}
</script>

<style scoped>
	.landing-header {
		width: 100%;
		display: flex;
		align-items: center;
		position: sticky;
		top: 0;
		padding: 28px 28px 28px 48px;
		box-sizing: border-box;
		background-color: var(--white);
		z-index: 1;
	}

	.logo {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.header-middle {
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-right: 32px;
	}

	.header-right {
		display: flex;
		align-items: center;
	}

	@media only screen and (max-width: 768px) {
		.landing-header {
			padding: 28px 36px;
		}
	}
</style>
