export const FileDefs = [
	{
		title: 'Figma',
		desc: 'Embed a Figma file',
		icon: require('filedefs/icons/figma.svg'),
		smallIcon: require('filedefs/icons/figma.svg'),
		codeField: true,
		buttonText: 'Embed Figma',
		tip: 'How to get an iframe code for Figma file?',
		tipLink: 'https://help.figma.com/hc/en-us/articles/360039827134-Embed-files-and-prototypes',
		type: 'figma',
	},
	{
		title: 'Invision',
		desc: 'Embed an Invision project',
		icon: require('filedefs/icons/invision.svg'),
		smallIcon: require('filedefs/icons/invision.svg'),
		codeField: true,
		buttonText: 'Embed Invision',
		tip: 'How to get an iframe code for Invision project?',
		tipLink: 'https://support.invisionapp.com/hc/en-us/articles/360027579812-Embedding-Screens-Prototypes',
		type: 'invision',
	},
	{
		title: 'Marvel',
		desc: 'Embed a Marvel prototype',
		icon: require('filedefs/icons/marvel.svg'),
		smallIcon: require('filedefs/icons/marvel.svg'),
		codeField: true,
		buttonText: 'Embed Marvel',
		tip: 'How to get an iframe code for Marvel prototype?',
		tipLink: 'https://help.marvelapp.com/hc/en-us/articles/360002747857-How-to-embed-your-prototype-on-Wordpress-or-any-web-page-blog-or-portfolio-',
		type: 'marvel',
	},
	// {
	// 	title: 'UXPin',
	// 	desc: 'Embed an UXPin prototype',
	// 	icon: require('./icons/uxpin.svg'),
	// 	codeField: true,
	// 	buttonText: 'Embed UXPin'
	// },



	// {
	// 	title: 'GitHub Gist',
	// 	desc: 'Embed a Gist from GitHub',
	// 	icon: require('./icons/github.svg'),
	// 	codeField: true,
	// 	buttonText: 'Embed GitHub Gist'
	// },
	{
		title: 'CodePen',
		desc: 'Embed a CodePen',
		icon: require('filedefs/icons/codepen.svg'),
		smallIcon: require('filedefs/icons/codepen.svg'),
		codeField: true,
		buttonText: 'Embed CodePen',
		tip: 'How to get an iframe code for CodePen?',
		tipLink: 'https://blog.codepen.io/documentation/embedded-pens/',
		type: 'codepen',
	},


	{
		title: 'Loom',
		desc: 'Embed a Loom recording',
		icon: require('filedefs/icons/loom.svg'),
		smallIcon: require('filedefs/icons/loom.svg'),
		codeField: true,
		buttonText: 'Embed Loom',
		tipLink: 'https://support.loom.com/hc/en-us/articles/360002208317-How-to-embed-your-video-into-a-webpage-',
		tip: 'How to get an iframe code for Loom recording?',
		type: 'loom',
	},
	{
		title: 'YouTube',
		desc: 'Embed YouTube videos',
		icon: require('filedefs/icons/youtube.svg'),
		smallIcon: require('filedefs/icons/youtube.svg'),
		codeField: true,
		buttonText: 'Embed YouTube',
		tipLink: 'https://support.google.com/youtube/answer/171780?hl=en',
		tip: 'How to get an iframe code for YouTube video?',
		type: 'youtube',
	},
	{
		title: 'Vimeo',
		desc: 'Embed Vimeo videos',
		icon: require('filedefs/icons/vimeo.svg'),
		smallIcon: require('filedefs/icons/vimeo.svg'),
		codeField: true,
		buttonText: 'Embed Vimeo',
		tipLink: 'https://vimeo.zendesk.com/hc/en-us/articles/224969968-Embedding-videos-overview',
		tip: 'How to get an iframe code for Vimeo video?',
		type: 'vimeo',
	},

	{
		title: 'Miro',
		desc: 'Embed a Miro board',
		smallIcon: require('filedefs/icons/miro-32.png'),
		icon: require('filedefs/icons/miro.svg'),
		codeField: true,
		buttonText: 'Embed Miro',
		tip: 'How to get an iframe code for Miro board?',
		tipLink: 'https://help.miro.com/hc/en-us/articles/360016335640-Embedding-Editable-Boards-into-Websites',
		type: 'miro',
	},
	{
		title: 'Whimsical',
		desc: 'Embed a Whimsical board',
		icon: require('filedefs/icons/whimsical.png'),
		smallIcon: require('filedefs/icons/whimsical.png'),
		codeField: true,
		buttonText: 'Embed Whimsical',
		tip: 'How to get an iframe code for Whimsical board?',
		tipLink: 'https://help.whimsical.com/en/articles/3722574-how-to-embed-whimsical-boards',
		type: 'whimsical',
	},
	// {
	// 	title: 'Prezi',
	// 	desc: 'Embed Prezi slides',
	// 	icon: require('./icons/prezi.svg'),
	// 	codeField: true,
	// 	buttonText: 'Embed Prezi'
	// },
	{
		title: 'Airtable',
		desc: 'Embed Airtable base',
		icon: require('filedefs/icons/airtable.svg'),
		smallIcon: require('filedefs/icons/airtable.svg'),
		codeField: true,
		buttonText: 'Embed Airtable',
		tipLink: 'https://support.airtable.com/hc/en-us/articles/217846478-Embedding-a-view-or-base',
		tip: 'How to get an iframe code for Airtable base?',
		type: 'airtable',
	},
	{
		title: 'Google Maps',
		desc: 'Embed Google Maps',
		icon: require('filedefs/icons/googlemaps.svg'),
		smallIcon: require('filedefs/icons/googlemaps.svg'),
		codeField: true,
		buttonText: 'Embed Google Maps',
		tip: 'How to get an iframe code for Google Map?',
		tipLink: 'https://support.google.com/maps/answer/144361?co=GENIE.Platform%3DDesktop&hl=en',
		type: 'gmaps'
	},
	{
		title: 'Embed',
		desc: 'For any app or service',
		icon: require('filedefs/icons/embed.svg'),
		smallIcon: require('filedefs/icons/embed.svg'),
		codeField: true,
		buttonText: 'Embed',
		type: 'embed'
	},
]
