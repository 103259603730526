<template>
	<div class="lp-main">
		<div class="lp-container">
			<LandingHeader/>

			<div class="lp-content">
				<div class="header-1 mb-4">Meet Spaceli 2.0</div>
				<div class="mb-2">Hey there,</div>
				<div class="mb-2">Eric from Spaceli here.</div>
				<div class="mb-2">Thanks so much for being with us! We’ve really appreciated your support, and now it’s time to take the next step. Over the past few months, Google’s API has changed significantly, and unfortunately, we can no longer support the way Spaceli 1.0 works. As a result, we’ve made the decision to discontinue Spaceli 1.0 on December 1st.</div>
				<div class="mb-2">But here’s the exciting part — we’ve built Spaceli 2.0!</div>
				<div class="mb-2">We’ve completely redesigned Spaceli and added some of the most requested features, like video support, dark mode, and more. Spaceli is faster, gives you greater control over who can access your spaces, and the best part — it only requires access to the specific files you’ve shared, not all of your files. And we’re continuing to actively improve it! All you need to do is log in to Spaceli 2.0 and start exploring.</div>
				<div class="mb-2">Moving to Spaceli 2.0 is super simple. Since all your data is stored in Google Drive, just log in to Spaceli 2.0, create your spaces using your existing folders, and share them with your colleagues. That’s it!</div>
				<div class="mb-2">If you have any questions, feel free to reach out to us in the chat—we’re happy to help!</div>

				<div class="header-3 mt-8">What’s New in Spaceli 2.0: Key Features and Improvements</div>
				<div class="mb-2 mt-4">This list captures the key differences between Spaceli 1.0 and Spaceli 2.0 and highlights the improvements you can enjoy in the new version.</div>
				<div class="mb-2"><strong class="mr-2">Refined Google API Permissions:</strong> We’ve adjusted the scope of permissions requested from the Google API. Now, Spaceli only requires access to the specific files you’ve shared, rather than needing access to all your files. This change helps ensure your privacy and allows us to build your knowledge base using only the necessary files.</div>
				<div class="mb-2"><strong class="mr-2">Introduction of Workspaces:</strong>Spaces are now grouped under workspaces, which are associated with your domain. Workspaces are ideal for organizing teams and projects — one workspace per team or project, making it easier to manage spaces within a specific group.</div>
				<div class="mb-2"><strong class="mr-2">Revamped Knowledge Base Storage:</strong>We’ve completely restructured how the knowledge base is stored. Now, we store the IDs of your documents (don’t worry, we still don’t store, index, or access your document data). The knowledge base structure and document dependencies are stored on Spaceli servers, so they no longer rely on your Google Drive folder structure.</div>
				<div class="mb-2"><strong class="mr-2">Faster Loading Times:</strong>By storing space structures on our servers, we’ve significantly improved loading speeds, making your experience smoother and faster.</div>
				<div class="mb-2"><strong class="mr-2">Nested Files:</strong>You can now create nested documents under other documents, eliminating the need to use folders as containers. Documents can now be directly nested within other documents and will still be stored in your Google Drive.</div>
				<div class="mb-2"><strong class="mr-2">New Supported Formats:</strong>We’ve added support for more formats, including video, PDFs, and more, enhancing your ability to store diverse content types.</div>
				<div class="mb-2"><strong class="mr-2">Dark Mode:</strong>Dark mode is now available, providing a more comfortable viewing experience, especially in low-light environments.</div>
				<div class="mb-2"><strong class="mr-2">Improved Knowledge Base Layout:</strong>The knowledge base now has a more intuitive layout with a cleaner, more aesthetically pleasing design. There’s also a new, more efficient document viewer.</div>
				<div class="mb-2"><strong class="mr-2">White Label Option:</strong>Public spaces no longer display the Spaceli label. Soon, we will also release a feature allowing you to use custom domains for your spaces.</div>
				<div class="mb-2"><strong class="mr-2">And Much More:</strong>We’ve introduced several other enhancements and improvements to make your experience better.</div>
			</div>

		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import LandingHeader from 'landing/LandingHeader.vue'

	@Component({
		components: {
			LandingHeader
		}
	})

	export default class Spaceli2 extends Vue {
		//
	}
</script>

<style scoped>

</style>
