<template>
	<div class="landing-main">
		<LandingHeader/>

		<div class="hero-section">
			<div class="text-5xl font-bold hero-header">Turn Google Drive into a team knowledge base</div>
			<div class="hero-desc">Transform all your Google Workspace files, docs, slides, and sheets into a searchable Team Wiki</div>
<!--			Organize Google Docs in a way where people can find them.-->
			<div class="buttons-container">
				<a href="https://app2.spaceli.io/login" class="rounded-button-medium db-50-button">Get started</a>
			</div>
			<img class="hero-image" src="img/heroshot.png">
		</div>

		<div class="cases-container">
			<div class="case-cont">
				<div class="case-header">For you</div>
				<div class="description">Organize your personal notes. Track your ideas and projects.</div>
			</div>
			<div class="case-cont central-case">
				<div class="case-header">For your team</div>
				<div class="description">Create a single source of truth for all project documentation, on all stages. Turn Google Drive into a hub for everything your team is
					working on.
				</div>
			</div>
			<div class="case-cont">
				<div class="case-header">For your users</div>
				<div class="description">Knowledge bases for your Products, User Guides, FAQs</div>
			</div>
		</div>

		<div class="section right-image-section">
			<div class="img-section video-container">
				<video class="video-slide" autoplay="" loop="" muted="" playsinline="">
					<source type="video/mp4" data-src="./assets/spaceli_video.mp4" src="./assets/spaceli_video.mp4">
				</video>
			</div>
			<div class="text-section left-text">
				<div class="text-container">
					<h2 class="section-header">Create a knowledge base in less than 30 seconds</h2>
					<div class="description">Use your existing Google Drive folders and files.</div>
					<div class="buttons-container">
						<a href="https://app.spaceli.io/login" class="rounded-button-medium db-50-button">Get started</a>
					</div>
				</div>
			</div>
		</div>

		<div class="section">
			<div class="img-section">
				<img class="discover-img" src="img/discover.png">
			</div>
			<div class="text-section right-text">
				<div class="text-container">
					<h2 class="section-header">Make it easy to browse and discover</h2>
					<div class="description">Organizing all the docs in a way where people can find them. Nested tree menu and speedy interface lets you find the docs you're
						looking for faster.
					</div>
					<div class="buttons-container">
						<a href="https://app.spaceli.io/login" class="rounded-button-medium db-50-button">Get started</a>
					</div>
				</div>
			</div>
		</div>

		<div class="section right-image-section">
			<div class="img-section">
				<img class="discover-img" src="img/embed.png">
			</div>
			<div class="text-section left-text">
				<div class="text-container">
					<h2 class="section-header">Embed your current tools</h2>
					<div class="description">Create a single source of truth for all project documentation, on all stages. Embed apps inside space. Linking up all your tools in one
						place.
					</div>
					<div class="services-icons-container">
						<img
								class="service-icon"
								v-for="element in externalFiles"
								:key="element.title"
								:src="element.icon">
					</div>
					<div class="buttons-container">
						<a href="https://app.spaceli.io/login" class="rounded-button-medium db-50-button">Get started</a>
					</div>
				</div>
			</div>
		</div>

		<div class="section">
			<div class="img-section">
				<img class="discover-img" src="img/nested.png">
			</div>
			<div class="text-section right-text">
				<div class="text-container">
					<h2 class="section-header">Nested pages</h2>
					<div class="description">Nest pages infinitely to put everything in its place.</div>
					<div class="buttons-container">
						<a href="https://app.spaceli.io/login" class="rounded-button-medium db-50-button">Get started</a>
					</div>
				</div>
			</div>
		</div>

		<div class="more-section">
			<h2 class="section-header">And more...</h2>
			<div class="more-container">
				<div class="more-item">
					<IconCheck class="more-item-icon"/>
					<div class="description">Everything saves to Google Drive</div>
				</div>

				<div class="more-item">
					<IconCheck class="more-item-icon"/>
					<div class="description">Works with Shared Drives</div>
				</div>

				<div class="more-item">
					<IconCheck class="more-item-icon"/>
					<div class="description">Unified search</div>
				</div>

				<div class="more-item">
					<IconCheck class="more-item-icon"/>
					<div class="description">Dashboard for your wikis</div>
				</div>

				<div class="buttons-container j-center">
					<a href="https://app.spaceli.io/login" class="rounded-button-medium db-50-button">Get started</a>
				</div>
			</div>
		</div>

		<div class="pricing-section">
			<h2 class="section-header">Pricing</h2>
			<div class="hero-desc text-container">Spaceli is free. It will have premium plan with some additional features like custom domain. But current functionality will stay free.</div>
		</div>

		<div class="footer">
			<router-link to="/permissions" class="rounded-button-big primary-text-button">Permissions</router-link>
			<router-link to="/terms-privacy" class="rounded-button-big primary-text-button">Terms & Privacy</router-link>
		</div>

	</div>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import LogoTextGradient from 'icons/logo/LogoTextGradient.vue'
	import IconCheck from 'icons/icons/IconCheck.vue'
	import {FileDefs} from 'filedefs/FileDefs'
	import LandingHeader from 'landing/LandingHeader.vue'

	@Component({
		components: {
			LandingHeader,
			LogoTextGradient,
			IconCheck
		}
	})

	export default class Landing extends Vue {
		private externalFiles = FileDefs
	}
</script>

<style scoped>
	.landing-main {
		width: 100%;
		height: 100%;
		background-color: var(--white);
		overflow-x: hidden;
		position: relative;
		box-sizing: border-box;
	}

	.hero-section {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 56px 48px 48px 48px;
		box-sizing: border-box;

		margin-bottom: 64px;
		border-bottom: none;
	}

	.description {
		color: var(--g-60);
		font-size: 16px;
	}

	.hero-header {
		text-align: center;
		margin-bottom: 16px;
	}

	.hero-desc {
		color: var(--g-60);
		font-size: 20px;
		text-align: center;
	}

	.hero-image {
		margin-top: 20px;
		width: 100%;
		max-width: 1050px;
	}

	.case-cont {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 64px 16px 64px;
		box-sizing: border-box;
		width: 430px;
	}

	.case-header {
		font-size: 28px;
		font-weight: bold;
		margin-bottom: 16px;
	}

	.central-case {
		border-left: 1px solid var(--g-20);
		border-right: 1px solid var(--g-20);
	}

	.section {
		flex: none;
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		padding: 64px 48px;
		border-bottom: 1px solid var(--g-20);
	}

	.right-image-section {
		flex-direction: row-reverse;
	}

	.text-section {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 24px;
		box-sizing: border-box;
	}

	.text-container {
		max-width: 420px;
		position: relative;
	}

	.img-section {
		padding: 0 24px;
	}

	.discover-img {
		max-height: 524px;
	}

	img,
	video {
		max-width: 100%;
	}

	.right-text {
		padding-right: 96px;
	}

	.left-text {
		padding-left: 96px;
	}

	.video-container {
		max-width: 50%;
	}

	.video-slide {
		border-radius: 12px;
		box-shadow: 0 5px 40px 0 rgba(145, 145, 145, 0.3);
	}

	.buttons-container {
		display: flex;
		margin-top: 24px;
	}

	.section-header {
		font-weight: bold;
		font-size: 36px;
		margin-bottom: 20px;
		position: relative;
	}

	.cases-container {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-bottom: 48px;
	}

	.more-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 120px 0;
		border-bottom: 1px solid var(--g-20);
	}

	.pricing-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 120px 0;
		border-bottom: 1px solid var(--g-20);
	}

	.more-container {
		width: 284px;
		display: flex;
		flex-direction: column;
	}

	.more-item {
		display: flex;
		padding: 8px 0;
		align-items: center;
	}

	.more-item-icon {
		width: 24px;
		height: 24px;
		color: var(--db-40);
		margin-right: 8px;
	}

	.j-center {
		justify-content: center;
	}

	.footer {
		padding: 28px;
		display: flex;
	}

	.services-icons-container {
		display: flex;
		flex-wrap: wrap;
		margin-top: 24px;
	}

	.service-icon {
		width: 32px;
		height: 32px;
		margin: 0 20px 12px 0;
	}

	@media only screen and (max-width: 1024px) {
		.section {
			flex-direction: column;
			height: auto;
		}

		.img-section {
			padding-bottom: 24px;
		}

		.right-text {
			padding-right: 24px;
		}

		.left-text {
			padding-left: 24px;
		}
	}

	@media only screen and (max-width: 768px) {
		.hero-section {
			padding: 36px;
		}

		.hero-desc {
			font-size: 16px;
		}

		.cases-container {
			flex-direction: column;
		}

		.case-cont {
			width: 100%;
			padding: 0 12%;
			margin-bottom: 32px;
		}

		.central-case {
			border: none;
		}

		.img-section {
			display: none;
		}

		.text-section {
			padding: 0;
		}

		.text-container {
			max-width: 100%;
		}
	}
</style>
