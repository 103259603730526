<template>
	<div class="lp-main">
		<div class="lp-container">
			<LandingHeader/>

			<div class="lp-content">
				<div class="header-1 margin-b-32">Privacy Policy</div>
				<div class="paragraph-text margin-b-16">This policy is effective as of 19 November 2020.</div>
				<div class="paragraph-text">
					Your privacy is important to us. It is Spaceli's policy to respect your privacy regarding any information we may collect from you across our website, <a
						href="https://spaceli.io">https://spaceli.io</a>, and other sites we own and operate.<br>
					We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent.
					We also let you know why we’re collecting it and how it will be used.<br>
					We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially
					acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification.<br>
					Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and
					cannot accept responsibility or liability for their respective privacy policies.<br>
					You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired
					services.<br>
					Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how
					we handle user data and personal information, feel free to contact us.<br>
				</div>
				<h3 class="header-3">Information We Collect</h3>
				<div class="paragraph-text">
					We do not collect or store your data. All data stored in your Google Drive. Spaceli makes API calls to show you files and folders in a tree-like navigation.
					What we don't save your Drive files and contacts.
				</div>
				<h3 class="header-3">How We Use Information</h3>
				<h4 class="margin-b-8">Google Drive API</h4>
				<div class="paragraph-text">Spaceli uses the Google Drive API for the following operations:</div>
				<ul>
					<li>Creating a folder for space</li>
					<li>Creating files/folders in your space</li>
					<li>Listing files and folders in a tree-like navigation</li>
					<li>Previewing file content</li>
					<li>Displaying file metadata (last modifying user and date/time)</li>
					<li>Accessing the users shared folders when requested by the user via browser URL.</li>
					<li>Moving files/folders</li>
					<li>Sending files/folders to trash</li>
					<li>Copying files/folders</li>
				</ul>
				<div class="paragraph-text margin-b-16">All operations listed above are initiated via user interaction.</div>
				<h4 class="margin-b-8">Google basic account info</h4>
				<div class="paragraph-text">Spaceli uses Google basic account info for the following:</div>
				<ul class="margin-b-16">
					<li>Displaying a profile photo</li>
					<li>Enable user sessions (signing in/out)</li>
					<li>Displaying username</li>
				</ul>
				<h4 class="margin-b-8">Google contacts API</h4>
				<div class="paragraph-text">Spaceli uses Google contacts info for inviting users via email to collaborate.</div>
				<h3 class="header-3">With Whom We Share Information</h3>
				<div class="paragraph-text margin-b-64">We don’t share any personally identifying information publicly or with third-parties.</div>


				<div class="header-1 margin-b-8">Terms of Service</div>
				<h3 class="header-3">Terms</h3>
				<div class="paragraph-text">By accessing the website at https://spaceli.io, you are agreeing to be bound by these terms of service, all applicable laws and
					regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited
					from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.
				</div>
				<h3 class="header-3">Use License</h3>
				<div class="paragraph-text">Permission is granted to temporarily download one copy of the materials (information or software) on Spaceli's website for personal,
					non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
				</div>
				<ul>
					<li>modify or copy the materials;</li>
					<li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
					<li>attempt to decompile or reverse engineer any software contained on Spaceli's website;</li>
					<li>remove any copyright or other proprietary notations from the materials;</li>
					<li>transfer the materials to another person or "mirror" the materials on any other server.</li>
				</ul>
				<div class="paragraph-text">This license shall automatically terminate if you violate any of these restrictions and may be terminated by Spaceli at any time. Upon
					terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in
					electronic or printed format.
				</div>
				<h3 class="header-3">Disclaimer</h3>
				<div class="paragraph-text">The materials on Spaceli's website are provided on an “AS IS” basis. Spaceli makes no warranties, expressed or implied, and hereby
					disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose,
					or non-infringement of intellectual property or other violation of rights.
					Further, Spaceli does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website
					or otherwise relating to such materials or on any sites linked to this site.
				</div>
				<h3 class="header-3">Limitations of Liability</h3>
				<div class="paragraph-text">In no event shall Spaceli or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit,
					or due to business interruption) arising out of the use or inability to use the materials on Spaceli's website, even if Spaceli or a Spaceli authorized
					representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied
					warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
				</div>
				<h3 class="header-3">Accuracy of materials</h3>
				<div class="paragraph-text">The materials appearing on Spaceli's website could include technical, typographical, or photographic errors. Spaceli does not warrant
					that any of the materials on its website are accurate, complete or current. Spaceli may make changes to the materials contained on its website at any time
					without notice. However Spaceli does not make any commitment to update the materials.
				</div>
				<h3 class="header-3">Links</h3>
				<div class="paragraph-text">Spaceli has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The
					inclusion of any link does not imply endorsement by Spaceli of the site. Use of any such linked website is at the user's own risk.
				</div>
				<h3 class="header-3">Modifications</h3>
				<div class="paragraph-text margin-b-64">Spaceli may revise these terms of service for its website at any time without notice. By using this website you are agreeing
					to be bound by the then current version of these terms of service.
				</div>

				<div class="header-1 margin-b-32">Contact Us</div>
				<div class="paragraph-text">If you have any questions about this Privacy Policy or Terms of use, please contact us: welcome@spaceli.io</div>

			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import LandingHeader from 'landing/LandingHeader.vue'

	@Component({
		components: {
			LandingHeader
		}
	})

	export default class TermsPrivacy extends Vue {
		//
	}
</script>

<style scoped>

</style>
