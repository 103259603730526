<template>
	<div class="app-main">
<!--		<transition :name="routingAnimation()">-->
			<router-view/>
<!--		</transition>-->
	</div>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'

	const ANIMATIONS = ['full-slide-top', 'full-slide-right', 'full-slide-down', 'full-slide-left']

	@Component({
		components: {
		}
	})
	export default class App extends Vue {
		routingAnimation() {
			const index = Math.floor(Math.random() * ANIMATIONS.length)
			return ANIMATIONS[index]
		}
	}
</script>

<style>
	#app {
		width: 100%;
		height: 100%;
		position: relative;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: var(--primary-color);
	}

	.app-main {
		width: 100%;
		height: 100%;
		position: relative;
		background-color: var(--white);
	}

	.loading-app {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s ease-in;
	}

	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}

	.slide-enter-active {
		transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
		position: absolute;
	}

	.slide-enter-active {
		transition-delay: 0.1s;
	}

	.slide-enter {
		opacity: 0;
		transform: translateY(15%);
	}
</style>
